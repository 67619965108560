import React, { useState, useEffect } from "react";
import cofigs from "../../libs/configs";
import Web3Token from "web3-token";
import Cookies from "js-cookie";
import Web3 from "web3";
import axios from "axios";
import { Modal ,notification} from "antd";
import Avvvatars from "avvvatars-react";
import Tags from "../Tags/Tags";
import Profile from "../Profile/Profile";
import "./UserInfo.css";
function UserInfo(props) {
  const [currentAddress, setCurrentAddress] = useState([]);
  const [profileModal, setProfileModal] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [isEthereum,setIsEthereum]=useState(true);
  useEffect(async () => {


    if (typeof window.ethereum !== 'undefined' && typeof window.web3 !== 'undefined') {
      await initAccount();

      window.ethereum.on("accountsChanged", function () {
        initAccount();
      });
    } else{
      setIsEthereum(false)
    }
 


  }, []);

  var handleConnect = async () => {

    if(isEthereum){
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      if (accounts[0] != null) {
        //get nonce
  
        var nonce = (
          await axios.get(cofigs.serverUrl + "nonce", {
            params: {
              address: accounts[0],
            },
          })
        ).data.nonce;
  
        var setToken = await signLogin(accounts[0], nonce);
        if (setToken) {
          setCurrentAddress(accounts[0]);
        }
      }
    }else{
      notification.open({
        duration:2,
        message:"Please use Google Chrome and install Metamask"
      })
      
    }
    


  };

  var hideProfileModal = () => {
    setProfileModal(false);
  };
  var editProfile = () => {
    setProfileModal(true);
  };

  var signLogin = async (currentAddress, nonce) => {
  
    var setToken = true;
    var token = Cookies.get(currentAddress);
    if (token == null) {
      var web3 = new Web3(window.ethereum);
      try {
        token = await Web3Token.sign(
          (msg) => web3.eth.personal.sign(msg, currentAddress),
          { expires_in: "30d", nonce: nonce }
        );
        // var verifyRespone = await axios.post(cofigs.serverUrl + "verify", "", {
        //   headers: {
        //     Authorization: token,
        //   },
        // });

        Cookies.set(currentAddress, token, {
          expires: 30,
        });

        // if (verifyRespone.data.result.address == currentAddress) {
        //
        //   console.log(verifyRespone.data);
        //   //update app
        //   // window.ethereum.emit("message", { type: "updateLogin", data: false });
        // } else {
        //   setToken = false;
        // }
      } catch (error) {
        setToken = false;
      }
    }
    return setToken;
  };

  var initAccount = async () => {
    var accounts = await window.ethereum.request({
      method: "eth_accounts",
    });

    if (accounts[0] != null && Cookies.get(accounts[0]) != null) {
      setCurrentAddress(accounts[0]);

      // await getUserInfo(accounts[0]);
    } else {
      setCurrentAddress("");
    }
  };

  var getShortAddress = (_address) => {
    let end = _address.length;
    let addressShort =
      _address.substring(0, 5) + "..." + _address.substring(end - 4, end);
    return addressShort;
  };

  var getUserInfo = async (currentAddress) => {
    var userInfo = await axios.get(cofigs.serverUrl + "profile_query", {
      headers: {
        Authorization: Cookies.get(currentAddress),
      },
    });
    console.log(userInfo.data.result.avatar);
    setUserInfo(userInfo.data.result);
    console.log(userInfo);
  };

  var saveProfile = async () => {
    getUserInfo(currentAddress);
    hideProfileModal();
  };

  return (
    <div className="userinfo_container">
      {currentAddress == "" ? (
        <div className="walletLogin_btn" onClick={handleConnect.bind(this)}>
          Wallet Login
        </div>
      ) : (
        <div className="userinfo">
          <Modal
            title="Profile"
            open={profileModal}
            footer={null}
            onCancel={hideProfileModal.bind(this)}
            destroyOnClose="true"
          >
            <Profile
              currentAddress={currentAddress}
              saveProfile={saveProfile.bind(this)}
            ></Profile>
          </Modal>

          <div className="left_avatar">
            {userInfo.avatar == "" || userInfo.avatar == undefined ? (
              <Avvvatars value={currentAddress} size={32} style="shape" />
            ) : (
              <img
                src={userInfo.avatar}
                width={32}
                className="left_user_avatar"
              />
            )}
          </div>
          {/* <div className="left_nickname">{userInfo.nickname}</div> */}
          <div className="left_address">{getShortAddress(currentAddress)}</div>
          {/* <Tags
            myTags={userInfo.tags == undefined ? [] : userInfo.tags}
            edit={false}
          ></Tags> */}
          {/* <div className="left_bio">{userInfo.bio}</div> */}

          {/* <div className="editProfile" onClick={editProfile.bind(this)}>
            Edit Profile
          </div> */}
        </div>
      )}
    </div>
  );
}
export default UserInfo;
