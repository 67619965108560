import React, { Component } from "react";
import { Modal } from "antd";
import UserInfo from "../UserInfo/UserInfo";
import Select from "react-select";
import Avvvatars from "avvvatars-react";
import InfiniteScroll from "react-infinite-scroll-component";
import NewNoise from "../NewNoise/NewNoise";
import axios from "axios";
import Cookies from "js-cookie";
import cofigs from "../../libs/configs";
import categoryJSON from "../../libs/category.json";
import NewsFrom from "../NewsFrom/NewsFrom";
import likeiconlight from "../../assets/likeicon_light.svg";
import likediconlight from "../../assets/likedicon_light.svg";
import deleteiconlight from "../../assets/deleteicon_light.svg";

import likeicondark from "../../assets/likeicon_dark.svg";
import likedicondark from "../../assets/likedicon_dark.svg";
import deleteicondark from "../../assets/deleteicon_dark.svg";

import logo from "../../assets/logo.svg";
import logodark from "../../assets/logo_dark.svg";

import lightModeIcon from "../../assets/lightMode.svg";
import darkModeIcon from "../../assets/darkMode.svg";

import lightTwitter from "../../assets/twitter_light.svg"
import darkTwitter from "../../assets/twitter_dark.svg"
import "./News.css";
class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 10,
      pageNum: 0,
      noiseModal: false,
      deleteModal: false,
      noiseBtn: true,
      deleteItem: "",
      options: [
        { value: "All", label: "📰 All" },
        { value: "My Posted", label: "📝 My Posted" },
        { value: "Liked", label: "⭐ Liked" },
      ].concat(categoryJSON),
      category: "All",
      currentAddress: "",
      noisesList: [],
      firstLoad: true,
      theme: "light",
    };
  }

  async componentDidMount() {
    if (
      localStorage.getItem("theme") == null ||
      localStorage.getItem("theme") == "light"
    ) {
      this.setState({
        theme: "light",
      });
      document.body.setAttribute("theme", "");
    } else {
      this.setState({
        theme: "dark",
      });
      document.body.setAttribute("theme", "dark");
    }

    var accounts;
    const { ethereum } = window;
    console.log("ethereum", ethereum);
    if (
      typeof window.ethereum !== "undefined" &&
      typeof window.web3 !== "undefined"
    ) {
      accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
    } else {
      accounts = [];
    }

    const currentAddress = accounts[0] == undefined ? "" : accounts[0];

    var noiseBtn = true;
    var whitelist = (await axios.get(cofigs.serverUrl + "whitelist", {})).data;
    if (whitelist.indexOf(currentAddress) != -1) {
      noiseBtn = false;
    }

    await this.loadNewsData();

    this.setState({
      noiseBtn: noiseBtn,
      currentAddress: currentAddress,
      firstLoad: false,
    });
  }
  shortAddress(address) {
    let end = address.length;
    let addressShort =
      address.substring(0, 5) + "..." + address.substring(end - 4, end);
    return addressShort;
  }

  getNickname = (item) => {
    if (item.ex != undefined) {
      if (item.ex[0].nickname != "") {
        return item.ex[0].nickname;
      } else {
        return this.shortAddress(item.address);
      }
    } else {
      return this.shortAddress(item.address);
    }
  };

  getAvatar = (item) => {
    if (item.ex != undefined) {
      if (item.ex[0].avatar != "") {
        return <img src={item.ex[0].avatar} className="noise_avatar" />;
      } else {
        return <Avvvatars value={item.address} size={32} style="shape" />;
      }
    } else {
      return <Avvvatars value={item.address} size={32} style="shape" />;
    }
  };

  getNicknameNoProfile = (address) => {
    return this.shortAddress(address);
  };

  getAvatarNoProfile = (address) => {
    return <Avvvatars value={address} size={32} style="shape" />;
  };

  updateNews = async () => {
    this.setState({
      noiseModal: false,
    });
    await this.changeSelect({ value: "All" });
  };

  getNewsData = async (category, address, page) => {
    var news = await axios.get(cofigs.serverUrl + "news_query", {
      params: {
        category: category,
        address: address,
        page: page,
        limit: this.state.limit,
      },
    });
    console.log("news", news);
    return news.data;
  };

  changeSelect = async (category) => {
    var currentAddress = this.state.currentAddress;

    var newsData = await this.getNewsData(category.value, currentAddress, 1);

    var hasMore = true;
    if (newsData.length == this.state.limit) {
      hasMore = true;
    } else {
      hasMore = false;
    }

    this.setState({
      category: category.value,
      noisesList: newsData,
      pageNum: 1,
      hasMore: hasMore,
    });
  };

  loadNewsData = async () => {
    var pageNum = this.state.pageNum + 1;
    var currentAddress = this.state.currentAddress;

    var newsData = await this.getNewsData(
      this.state.category,
      currentAddress,
      pageNum
    );
    var hasMore = true;
    if (newsData.length == this.state.limit) {
      hasMore = true;
    } else {
      hasMore = false;
    }

    if (pageNum == 1) {
      this.setState({
        noisesList: newsData,
        hasMore: hasMore,
        pageNum: pageNum,
      });
    } else {
      this.setState({
        noisesList: this.state.noisesList.concat(newsData),
        hasMore: hasMore,
        pageNum: pageNum,
      });
    }
  };
  showNoiseModal = () => {
    this.setState({
      noiseModal: true,
    });
  };

  hideModal = () => {
    this.setState({
      noiseModal: false,
      deleteModal: false,
    });
  };

  handleDelete = (deleteItem) => {
    console.log("id", deleteItem.id);
    console.log(("index", deleteItem.index));
    this.setState({
      deleteModal: true,
      deleteItem: deleteItem,
    });
  };

  deleteNoise = async () => {
    var token = Cookies.get(this.state.currentAddress);
    console.log("token", token);
    var result = await axios.post(
      cofigs.serverUrl + "news_delete",
      { id: this.state.deleteItem.id },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (result.data.result == "success") {
      var noisesList = this.state.noisesList.map((item, idx) => {
        if (idx == this.state.deleteItem.index) {
          return {
            ...item,
            deleted: true,
          };
        }
        return item;
      });

      this.setState({
        deleteModal: false,
        noisesList: noisesList,
      });
    }
  };
  calculateSince(datetime) {
    var tTime = new Date(datetime);
    var cTime = new Date();
    var sinceMin = Math.round((cTime - tTime) / 60000);
    if (sinceMin == 0) {
      var sinceSec = Math.round((cTime - tTime) / 1000);
      if (sinceSec < 10) var since = "10 seconds ago";
      else if (sinceSec < 20) var since = "20 seconds ago";
      else var since = "a minute ago";
    } else if (sinceMin == 1) {
      var sinceSec = Math.round((cTime - tTime) / 1000);
      if (sinceSec == 30) var since = "a minute ago";
      else if (sinceSec < 60) var since = "a minute ago";
      else var since = "1 minute ago";
    } else if (sinceMin < 45) var since = sinceMin + " minutes ago";
    else if (sinceMin > 44 && sinceMin < 60) var since = "1 hour ago";
    else if (sinceMin < 1440) {
      var sinceHr = Math.round(sinceMin / 60);
      if (sinceHr == 1) var since = " 1 hour ago";
      else var since = sinceHr + " hours ago";
    } else if (sinceMin > 1439 && sinceMin < 2880) var since = "1 day ago";
    else {
      const options = { month: "short", day: "numeric" };

      var since = tTime.toLocaleString("en-US", options);
    }
    return since;
  }

  changeCategoryByTag = async (tag) => {
    this.changeSelect({ label: tag, value: tag });
  };

  likeNews = async (id, index) => {
    const itemsCopy = [...this.state.noisesList];
    const updatedItem = { ...itemsCopy[index], liked: true };
    itemsCopy[index] = updatedItem;
    this.setState({ noisesList: itemsCopy });

    var token = Cookies.get(this.state.currentAddress);
    var result = await axios.post(
      cofigs.serverUrl + "news_like",
      { id: id, address: this.state.currentAddress },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    console.log("Result", result);
  };

  dislikeNews = async (id, index) => {
    const itemsCopy = [...this.state.noisesList];
    const updatedItem = { ...itemsCopy[index], liked: false };
    itemsCopy[index] = updatedItem;
    this.setState({ noisesList: itemsCopy });

    var token = Cookies.get(this.state.currentAddress);
    var result = await axios.post(
      cofigs.serverUrl + "news_dislike",
      { id: id, address: this.state.currentAddress },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    console.log("Result", result);
  };

  changeTheme = () => {
    console.log(localStorage.getItem("theme"));
    if (
      localStorage.getItem("theme") == null ||
      localStorage.getItem("theme") == "light"
    ) {
      this.setState({
        theme: "dark",
      });
      document.body.setAttribute("theme", "dark");
      localStorage.setItem("theme", "dark");
    } else {
      this.setState({
        theme: "light",
      });
      document.body.setAttribute("theme", "");
      localStorage.setItem("theme", "light");
    }
  };

  render() {
    return (
      <div className="news">
        <Modal
          title="Delete Noise"
          width={400}
          footer={null}
          open={this.state.deleteModal}
          onCancel={this.hideModal}
        >
          <div>Are you sure delete this noise</div>
          <div className="deleteNoiseButton" onClick={this.deleteNoise}>
            Delete
          </div>
        </Modal>
        <Modal
          title="New Post"
          open={this.state.noiseModal}
          footer={null}
          onCancel={this.hideModal}
          maskClosable={false}
          destroyOnClose={true}
        >
          <NewNoise
            publishNews={this.updateNews}
            address={this.state.currentAddress}
          ></NewNoise>
        </Modal>

        <div className="news_h">
          {this.state.theme == "dark" ? (
            <img src={logodark} width={70} />
          ) : (
            <img src={logo} width={70} />
          )}

          <div className="noise_menus">
            {/* <div className="twitterLink">
          <img src={twitterLogo} width={32} />
          </div> */}

            <Select
              className="noise_select"
              defaultValue={this.state.options[0]}
              // styles={{
              //   control: (provided, state) => ({
              //     ...provided,
              //     height: "50px",
              //     border: "1px solid #e5e7eb",
              //   }),
              // }}
              styles={
                this.state.theme == "dark"
                  ? {
                      control: (base, state) => ({
                        ...base,
                        height: "50px",
                        border: "1px solid #31363C",
                        background: "#171B21",
                        color: "#fff",
                      }),
                      singleValue: (base) => ({
                        ...base,
                        color: "#fff",
                      }),
                      option: (base, state) => ({
                        ...base,
                        color: state.isSelected ? "#fff" : "#fff",
                        backgroundColor: state.isSelected ? "#171B21" : "",
                        "&:hover": {
                          color: "#171B21",
                          background: "#fff",
                        },
                      }),
                      menu: (base, state) => ({
                        ...base,
                        background: "#2C2D31",
                      }),
                    }
                  : {
                      control: (provided, state) => ({
                        ...provided,
                        height: "50px",
                        border: "1px solid #e5e7eb",
                      }),
                    }
              }
              options={this.state.options}
              onChange={this.changeSelect}
              value={{
                value: this.state.category,
                label: this.state.category,
              }}
            />

            <div
              className="noise_btn"
              hidden={this.state.noiseBtn}
              onClick={this.showNoiseModal}
            >
              New Post
            </div>
            <UserInfo></UserInfo>

            <div className="changeTheme" onClick={this.changeTheme.bind(this)}>
              {this.state.theme == "dark" ? (
                <img src={darkModeIcon} width={32} />
              ) : (
                <img src={lightModeIcon} width={32} />
              )}
            </div>

            <a className="changeTheme" href="https://x.com/Web3Rickey" target="_blank">
              {this.state.theme == "dark" ? (
                <img src={darkTwitter} width={24} />
              ) : (
                <img src={lightTwitter} width={24} />
              )}
            </a>
          </div>
        </div>

        <div className="noises_list">
          <InfiniteScroll
            dataLength={this.state.noisesList.length}
            next={this.loadNewsData}
            hasMore={this.state.hasMore}
            loader={
              <p className="loadContent">
                <b>Loading</b>
              </p>
            }
            endMessage={
              this.state.firstLoad ? (
                <p className="loadContent">
                  <b>Loading</b>
                </p>
              ) : (
                <p className="loadContent">
                  <b>Yay! You have seen it all</b>
                  <p>
                    If you have interesting news to share, please send the link
                    to twitter and &nbsp;
                    <a
                      className="sharelink"
                      href="https://twitter.com/intent/tweet?text=@Web3Rickey%20Share%20News%20Link"
                      target="_blank"
                    >
                      @Web3Rickey
                    </a>
                  </p>
                </p>
              )
            }
          >
            {this.state.noisesList.map((item, index) => (
              <div className="noise_item" key={index} hidden={item.deleted}>
                <a href={item.link} target="_blank">
                  <div className="noise_content_top">
                    <div className="noise_content">
                      <div className="noise_title">
                        <NewsFrom link={item.link}></NewsFrom>
                        {/* <img
                          className="twitterAvatar"
                      
                            src={
                              "https://pbs.twimg.com/profile_images/1619062546740219909/iCyVVtYV_normal.jpg"
                            }
                          /> */}

                        <div className="noise_title_content">{item.title}</div>
                      </div>
                      <div className="noise_describtion">
                        {item.description}
                      </div>
                    </div>
                    {item.image != "" ? (
                      <div>
                        {/* <img src={item.image} className="noise_image" /> */}
                      </div>
                    ) : (
                      <div style={{ width: "0px" }}></div>
                    )}
                  </div>
                </a>

                <div className="noise_info">
                  <ul className="noise_tags">
                    {item.category.map((tag, index) => (
                      <li
                        onClick={this.changeCategoryByTag.bind(this, tag)}
                        key={index}
                      >
                        <span className="tagPrefix">#</span>
                        {tag}
                      </li>
                    ))}
                  </ul>
                  {/* <div className="noise_author">
                    {this.getAvatarNoProfile(item.address)}
                    &nbsp;&nbsp;
                    {this.getNicknameNoProfile(item.address)}
               
                  </div> */}
                  <div className="noise_operations">
                    <div className="noise_date">
                      {this.calculateSince(item.timestamp)}
                    </div>
                    <div className="likeanddelete">
                      {item.address == this.state.currentAddress ? (
                        <div
                          className="noise_operations_delete"
                          onClick={this.handleDelete.bind(this, {
                            id: item._id,
                            index: index,
                          })}
                        >
                          {this.state.theme=="dark"?(  <img src={deleteicondark} width={32} />):(  <img src={deleteiconlight} width={32} />)}
                        
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {this.state.currentAddress != "" ? (
                        <div className="noise_operations_like">
                          {item.liked ? (
                            this.state.theme == "dark" ? (
                              <img
                                src={likedicondark}
                                width={32}
                                onClick={this.dislikeNews.bind(
                                  this,
                                  item._id,
                                  index
                                )}
                              />
                            ) : (
                              <img
                                src={likediconlight}
                                width={32}
                                onClick={this.dislikeNews.bind(
                                  this,
                                  item._id,
                                  index
                                )}
                              />
                            )
                          ) : this.state.theme == "dark" ? (
                            <img
                              src={likeicondark}
                              width={32}
                              onClick={this.likeNews.bind(
                                this,
                                item._id,
                                index
                              )}
                            />
                          ) : (
                            <img
                              src={likeiconlight}
                              width={32}
                              onClick={this.likeNews.bind(
                                this,
                                item._id,
                                index
                              )}
                            />
                          )}
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}
export default News;
