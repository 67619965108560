import React, { Component } from "react";
import News from "../../components/News/News";
import Header from "../../components/Header/Header";
import "./App.css";
class App extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (

     
        <div  className="container">
          <News ></News>
        </div>

    );
  }
}
export default App;
