import React, { Component } from "react";
import { Input } from "antd";
import cofigs from "../../libs/configs";
import Cookies from "js-cookie";
import Tags from "../Tags/Tags";
import axios from "axios";
import "./Profile.css";
class Profile extends Component {
  constructor(props) {
    super(props);
    console.log(props.currentAddress);
    this.state = {
      currentAddress: props.currentAddress,
      nickname: "",
      bio: "",
      avatar: "",
      noiseError: true,
      tags: [],
    };
  }

  async componentDidMount(props) {
    var token = Cookies.get(this.props.currentAddress);
    console.log(token);
    var userInfo = await axios.get(cofigs.serverUrl + "profile_query", {
      headers: {
        Authorization: token,
      },
    });
    this.setState({
      nickname: userInfo.data.result.nickname,
      bio: userInfo.data.result.bio,
      avatar: userInfo.data.result.avatar,
      tags: userInfo.data.result.tags==undefined?[]:userInfo.data.result.tags,
    })
    console.log(userInfo.data.result);
  }

  changeBio = (e) => {
    this.setState({
      bio: e.target.value,
    });
  };
  changeNickname = (e) => {
    this.setState({
      nickname: e.target.value,
    });
  };
  changeLink = (e) => {
    this.setState({
      link: e.target.value,
    });
  };
  changeAvatar = (e) => {
    this.setState({
      avatar: e.target.value,
    });
  };
  changeTags = (tags) => {
    console.log(tags);
    this.setState({
      tags: tags,
    });
  };
  handleSave = async () => {
    var token = Cookies.get(this.state.currentAddress);
    if (
      this.state.nickname == "" &&
      this.state.bio == "" &&
      this.state.avatar == "" &&
      this.state.tags.length == 0
    ) {
      this.setState({
        noiseError: false,
      });
    } else {
      await axios.post(
        cofigs.serverUrl + "profile_update",
        {
          nickname: this.state.nickname,
          bio: this.state.bio,
          avatar: this.state.avatar,
          tags: this.state.tags,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      this.props.saveProfile();
    }
  };
  render() {
    return (
      <div>
        {/* <Genes></Genes> */}
        <Input
          onChange={this.changeNickname}
          placeholder="Nickname"
          className="profile_input"
          maxLength={20}
          value={this.state.nickname}
        ></Input>
        <Input.TextArea
          onChange={this.changeBio}
          placeholder="Bio"
          autoSize={{ minRows: 3, maxRows: 5 }}
          className="profile_textare"
          maxLength={200}
          value={this.state.bio}
        />
        <Input
          onChange={this.changeAvatar}
          placeholder="Avatar URL (optional)"
          className="profile_input"
          value={this.state.avatar}
        ></Input>
        <Tags
          changeTags={this.changeTags}
          myTags={this.state.tags}
          edit={true}
          max={3}
        ></Tags>
        <div className="profile_publish" onClick={this.handleSave}>
          Save
        </div>
        <div className="profile_error" hidden={this.state.noiseError}>
          Please update at least one item of information
        </div>
      </div>
    );
  }
}
export default Profile;
