import React, { Component } from "react";
import "./Header.css";
import logo from "../../assets/logo.svg";
import UserInfo from "../UserInfo/UserInfo";
class Header extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="header_bg">
        <div className="header">
          <div className="header_logo">
            <img src={logo} width={120} />
            <div className="slogan">No ads and boring news</div>
          </div>
          <UserInfo></UserInfo>
        </div>
      </div>
    );
  }
}
export default Header;
