import React, { useState, useEffect } from "react";
import "./Tags.css";
import tagsJson from "./Tags.json";
function Tags(props) {
 
  const [tags, setTags] = useState([]);
  const [max,setMax]=useState([props.max]);
  const [mytags, setMytags] = useState(props.myTags==undefined?null:props.myTags);
  const [edit, setEdit] = useState(props.edit);
  useEffect(() => {

    

    var newtags;
    if (!props.edit) {
      newtags = tagsJson.filter((tag) => props.myTags.indexOf(tag.id) != -1);
      console.log("newtags",newtags)
    }else{

     

      if(props.myTags.length==0){
          // newtags=tagsJson;
          newtags = tagsJson.map(tag => {
            if (tag.checked) tag.checked = false
            return tag
          }
        )
      }else{
          newtags = tagsJson.map(tag => {
              if (props.myTags.indexOf(tag.id) != -1) tag.checked = true
              return tag
            }
          )
      }
    
    }
    setTags(newtags);
    setMytags(props.myTags)
  }, [props.myTags]);

  const selectTag = (index) => {

    var exist = mytags.indexOf(tags[index].id);
    if(mytags.length<max){
     
      if (exist == -1) {
        mytags.push(tags[index].id);
        tags[index].checked = true;
      } else {
        mytags.splice(exist, 1);
        tags[index].checked = false;
      }
  
      setTags((arr) => [...arr]);
  
      props.changeTags(mytags);
    }else{
      if(exist!=-1){
        mytags.splice(exist, 1);
        tags[index].checked = false;
      }
      setTags((arr) => [...arr]);
      props.changeTags(mytags);
    }

  };
  return (
    <div>
      {edit ? (
        <div>
          <div className="tags_tip">
            <span className="tags_tips_txt">
              Select tags &nbsp;
            </span>
            <span className="tags_tips_num">({mytags.length}/{max})</span>
          </div>
          <ul className="tags">
            {tags.map((tag, index) => (
              <li
                key={index}
                onClick={selectTag.bind(this, index)}
                className={tag.checked ? "tag_checked" : "tag_uncheck"}
              >
                {tag.tagName}
                {tag.checked}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <ul className="tags" >
          {tags.map((tag, index) => (
            <li key={index} style={{cursor:"auto"}} className="tag_uncheck">
              {tag.tagName}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
export default Tags;
