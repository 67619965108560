import React, { useState, useEffect } from "react";
import "./NewsFrom.css";
function NewsFrom(props) {
  const [fromWebsite, setFromWebsite] = useState({backgroundColor:"#000",color:"#fff"});
  useEffect(() => {
    const domain = props.link.split("/")[2];
    var website;
    if (domain != undefined) {
      const websiteInfo = domain.split(".");

      if (websiteInfo.length == 2) {
        website = websiteInfo[0][0].toUpperCase() + websiteInfo[0].substring(1);
      } else if (websiteInfo.length == 3) {
        website = websiteInfo[1][0].toUpperCase() + websiteInfo[1].substring(1);
      }else if(websiteInfo.length == 4){
        
        website = websiteInfo[1][0].toUpperCase() + websiteInfo[1].substring(1);

      }
    }

   

    var fromWebsiteObj;

    switch (website) {
      case "Twitter":
        fromWebsiteObj = {
          text: website,
          color: "#4A99E9",
          backgroud: "#F1F5FF",
        };
        break;
      case "Medium":
        fromWebsiteObj = {
          text: website,
          color: "#fff",
          backgroud: "#000",
        };
        break;
      default:

        fromWebsiteObj = {
          text: website,
          color: "#000000",
          backgroud: "#F5F5F5",
        };
    }



    setFromWebsite(fromWebsiteObj);
  }, [props.link]);

  return (
    <div
      className="noise_from"
      style={{
        backgroundColor: fromWebsite.backgroud,
        color: fromWebsite.color,
      }}
    >
      {fromWebsite.text}
    </div>
  );
}

export default NewsFrom;
