import React, { Component } from "react";
import { Input, Alert, Tabs, Collapse } from "antd";
import Select from "react-select";
import axios from "axios";
import "./NewNoise.css";
import cofigs from "../../libs/configs";
import category from "../../libs/category.json";
import tokens from "../../libs/tokens.json";
import Cookies from "js-cookie";
import loading from "../../assets/loading.svg";
const { Panel } = Collapse;
class NewNoise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      link: "",
      image: "",
      category: "",
      address: props.address,
      noiseError: true,
      categories: category,
      tokens:tokens,
      token:"",
      autoLink: "",
      parseErrorText: "The link resolution is not supported",
      parseError: true,
      btnLoading: true,
      websiteWhitelist: [
        "medium",
        "mirror",
        "foresightnews",
        "theblockbeats",
        "hackernoon",
        "marsbit",
        "panewslab",
        "substack",
        "twitter",
        "coindesk",
        "cointelegraph",
        "blocktempo",
      ],
    };
  }
  componentDidMount() {}
  changeDescription = (e) => {
    this.setState({
      description: e.target.value,
    });
  };
  changeTitle = (e) => {
    this.setState({
      title: e.target.value,
    });
  };
  changeLink = (e) => {
    this.setState({
      link: e.target.value,
    });
  };
  changeImage = (e) => {
    this.setState({
      image: e.target.value,
    });
  };
  changeCategory = (category) => {
    this.setState({
      category: category,
    });
  };

  changeToken=(token)=>{
    console.log(token)
    this.setState({
      token:token
    })
  }

  handlePublish = async () => {
    var web3token = Cookies.get(this.state.address);



    if (
      this.state.title == "" ||
      this.state.link == "" ||
      this.state.category.length == 0
    ) {
      this.setState({
        noiseError: false,
      });
    } else {
      this.setState({
        noiseError: true,
      });

      var category = this.state.category;

      var newCategory = [];
      for (var index in category) {
        newCategory.push(category[index].value);
      }

      var token=this.state.token;
      
      var newToken=[]
      for(var index in token){
        newToken.push(token[index].value)
      }


      var news = {
        title: this.state.title,
        description: this.state.description,
        link: this.state.link,
        image: this.state.image,
        category: newCategory,
        token:newToken,
        address: this.state.address,
        timestamp: Date.now(),
      };

      await axios.post(cofigs.serverUrl + "news_add", news, {
        headers: {
          Authorization: web3token,
        },
      });
      this.props.publishNews();
    }
  };

  changeAutoLink = (e) => {
    this.setState({
      autoLink: e.target.value,
    });
  };

  parseLink = async () => {
    var token = Cookies.get(this.state.address);

    const link = this.state.autoLink;

    if (this.checkUrl(link)) {
      this.setState({
        parseError: true,
        btnLoading: false,
      });
      var webContent = await axios.get(cofigs.serverUrl + "parseLink", {
        params: {
          link: link,
        },
        headers: {
          Authorization: token,
        },
      });

      if (webContent.data != "fail") {
        this.setState({
          btnLoading: true,
          title: webContent.data.title,
          description: webContent.data.description,
          link: webContent.data.link,
          image: webContent.data.image,
        });
      } else {
        this.setState({
          btnLoading: true,
          parseErrorText: "Parsing Failed",
        });
      }
    } else {
      this.setState({
        parseError: false,
      });
    }
  };

  checkUrl = (link) => {
    var isUrl =
      /^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?$/.test(
        link
      );

    if (isUrl) {
      return true;
      // var domain = link.split("/")[2];
      // domain = domain.split(".");
      // domain.reverse();
      // var website = domain[1];
      // if (this.state.websiteWhitelist.indexOf(website) != -1) {
      //   return true;
      // } else {
      //   return false;
      // }
    } else {
      return false;
    }
  };

  render() {
    return (
      <div>
        {/* <Tabs
          centered
          defaultActiveKey="auto"
          items={[
            {
              label: "Automatic",
              key: "auto",
              children: <div></div>,
            },
            {
              label: "Manual",
              key: "manual",
              children: <div>1</div>,
            },
          ]}
        /> */}

        <Collapse
          defaultActiveKey={["1"]}
          ghost
          expandIconPosition="end"
          className="automatic_container"
        >
          <Panel header="Automatically parse link content" key="1">
            <div className="automatic">
              <div className="automatic_form">
                <Input
                  onChange={this.changeAutoLink}
                  placeholder="Please input link"
                  className="automatic_form_input"
                  maxLength={1000}
                ></Input>
                <div className="automatic_btn" onClick={this.parseLink}>
                  <img
                    src={loading}
                    width={24}
                    hidden={this.state.btnLoading}
                  />
                  <p>Parse</p>
                </div>
              </div>

              <div className="automatic_error" hidden={this.state.parseError}>
                {this.state.parseErrorText}
              </div>
              <div className="automatic_tip">
                {/* According to the link automatic parsing supports
                {this.state.websiteWhitelist.map((item, index) => (
                  <span key={index}>{", " + item}</span>
                ))} */}
              </div>
            </div>
          </Panel>
        </Collapse>

        <Input
          value={this.state.title}
          onChange={this.changeTitle}
          placeholder="Give it a title"
          className="noise_input"
          maxLength={1000}
        ></Input>
        <Input.TextArea
          value={this.state.description}
          onChange={this.changeDescription}
          placeholder="Give it a brief description"
          autoSize={{ minRows: 3, maxRows: 5 }}
          className="noise_textare"
          maxLength={2000}
        />
        <Input
          value={this.state.link}
          onChange={this.changeLink}
          placeholder="Link"
          className="noise_input"
        ></Input>
        <Input
          value={this.state.image}
          onChange={this.changeImage}
          placeholder="Image URL (optional)"
          className="noise_input"
        ></Input>

        <Select
          styles={{
   
            control: (provided, state) => ({
              ...provided,
              height: "auto",
              border: "1px solid #e5e7eb",
            })
          }}
          isMulti={true}
          placeholder={<div className="selectPlaceholder">Select categories (Max 4)</div>}
          options={this.state.categories}
          className="noise_category"
          onChange={this.changeCategory}
          isOptionDisabled={(option) => this.state.category.length >= 4}
         
        />

        <Select
          styles={{
            control: (provided, state) => ({
              ...provided,
              height: "auto",
              border: "1px solid #e5e7eb",
            }),placeholder:(styles)=>({
              ...styles,
            })
          }}
          isMulti={true}
          placeholder={<div className="selectPlaceholder">Select Tokens (Max 4)</div>}
          options={this.state.tokens}
          className="noise_token"
          onChange={this.changeToken}
          isOptionDisabled={(option) => this.state.token.length >= 4}
       
        />

        <div className="noise_publish" onClick={this.handlePublish}>
          Publish
        </div>
        <div className="noise_error" hidden={this.state.noiseError}>
          <Alert message="Please enter Title, Link, Category" type="error" />
        </div>
      </div>
    );
  }
}
export default NewNoise;
